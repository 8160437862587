import React from 'react';
import { Link } from 'gatsby';
import './css/desktopmenu.css';

const DesktopMenu = () => (
  <div id="desktopMenu">
    <center>
      <div id="desktopMenuText">
        <Link className="menuLinks" to="/" activeClassName="currentLink">STARTSEITE</Link>
        {' | '}
        <Link className="menuLinks" to="/portfolio" activeClassName="currentLink">PORTFOLIO</Link>
        {' | '}
        <Link className="menuLinks" to="/offers" activeClassName="currentLink">LEISTUNGEN</Link>
        {' | '}
        <Link className="menuLinks" to="/contact" activeClassName="currentLink">KONTAKT</Link>
      </div>
    </center>
  </div>
);

export default DesktopMenu;
