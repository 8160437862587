
import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SocialIcon } from 'react-social-icons';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import './css/footer.css';

const Footer = () => (
  <div id="footer">
    <div id="footerText">
      <center>
        <br />
        <SocialIcon url="https://www.instagram.com/vati.production/" style={{ marginRight: '10px' }} />
        <SocialIcon url="https://www.youtube.com/channel/UCGbmmJPP9vrWDPMFuCKYBPA" />
      </center>
      <p>
        {'©'}
        {new Date().getFullYear()}
        {' VATi Productions'}
      </p>
      <Hidden smDown>
        <center>
          <Link to="/privacy">Datenschutzerklärung</Link>
          {' | '}
          <Link to="/terms">AGBs</Link>
          {' | '}
          <Link to="/impressum">Impressum</Link>
        </center>
      </Hidden>
    </div>
  </div>
);

export default Footer;
