import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Link } from 'gatsby';
import Menu from './menu';
import DesktopMenu from './desktopmenu';
import './css/topbar.css';
import Logo from '../images/logo.svg';


const TopBar = () => (

  <div id="main">
    <div id="topbar">
      <AppBar position="sticky" style={{ background: '#000000' }}>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <Hidden mdUp>
              <div id="menu">
                <Menu fontSize="large" />
              </div>
            </Hidden>
          </Grid>
          <Grid item xs={8}>
            <Link to="/">
              <center>
                <Hidden smDown>
                  <Logo height="80px" style={{ marginTop: '5px' }} />
                </Hidden>
                <Hidden mdUp>
                  <Logo width="220px" style={{ marginTop: '-40px' }} />
                </Hidden>
              </center>
            </Link>
          </Grid>
        </Grid>
        <br />
        <Hidden smDown>
          <DesktopMenu />
        </Hidden>
      </AppBar>
    </div>
  </div>

);

export default TopBar;
