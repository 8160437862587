import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import MailIcon from '@material-ui/icons/Mail';
import VideocamIcon from '@material-ui/icons/Videocam';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import ChatIcon from '@material-ui/icons/Chat';
import ContactMailIcon from '@material-ui/icons/ContactMail';


const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  paper: {
    background: '#1d1d1d',
    color: '#ffffff',
    height: 'auto',
    '& .MuiDivider-root': {
      backgroundColor: '#ffffff',
      width: '90%',
      marginLeft: '5%',
    },
  },

});

export default function Menu() {
  const classes = useStyles();
  const [state, setState] = React.useState({

    left: false,

  });

  const toggleDrawer = (side, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = (side) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <List>
        <ListItem button component="a" href="/">
          <ListItemIcon>
            <HomeIcon style={{ color: '#ffffff' }} />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component="a" href="/portfolio">
          <ListItemIcon>
            <VideocamIcon style={{ color: '#ffffff' }} />
          </ListItemIcon>
          <ListItemText primary="Portfolio" />
        </ListItem>
        <ListItem button component="a" href="/offers">
          <ListItemIcon>
            <AttachMoneyIcon style={{ color: '#ffffff' }} />
          </ListItemIcon>
          <ListItemText primary="Leistungen" />
        </ListItem>
        <ListItem button component="a" href="/contact">
          <ListItemIcon>
            <MailIcon style={{ color: '#ffffff' }} />
          </ListItemIcon>
          <ListItemText primary="Kontakt" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button component="a" href="/privacy">
          <ListItemIcon>
            <PriorityHighIcon style={{ color: '#ffffff' }} />
          </ListItemIcon>
          <ListItemText primary="Datenschutzerklärung" />
        </ListItem>
        <ListItem button component="a" href="/terms">
          <ListItemIcon>
            <ChatIcon style={{ color: '#ffffff' }} />
          </ListItemIcon>
          <ListItemText primary="AGBs" />
        </ListItem>
        <ListItem button component="a" href="/impressum">
          <ListItemIcon>
            <ContactMailIcon style={{ color: '#ffffff' }} />
          </ListItemIcon>
          <ListItemText primary="Impressum" />
        </ListItem>
      </List>
    </div>
  );


  return (
    <div>
      <Button onClick={toggleDrawer('left', true)} style={{marginTop: '-20px'}}>
        <MenuIcon style={{ color: '#ffffff' }} />
      </Button>

      <Drawer classes={{ paper: classes.paper }} open={state.left} onClose={toggleDrawer('left', false)}>
        {sideList('left')}
      </Drawer>

    </div>
  );
}
