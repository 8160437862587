import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';


import TopBar from './topbar';
import Footer from './footer';

const mainTheme = createMuiTheme({
  palette: {
    primary: { main: '#b02828' }, // The Main Accent color
    secondary: { main: '#d1d1d1' }, // The Second Accent color
  },
});


const Layout = ({ children, title }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery
    {
      site {
        siteMetadata {
          title,
          description
        }
      }
    }
    `);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {title}
          {' | '}
          {data.site.siteMetadata.title}
        </title>

        <meta httpEquiv="language" content="DE" />
        <meta name="description" content={data.site.siteMetadata.description} />
      </Helmet>
      <ThemeProvider theme={mainTheme}>
        <TopBar />
        {children}
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="Verstanden"
          style={{ background: '#2B373B', width: '100%' }}
          buttonStyle={{ backgroundColor: '#b02828', fontSize: '13px' }}
          expires={150}
        >
          {'Diese Webseite benutzt Cookies. Mit der Nutzung der Webseite erklären Sie sich damit '}
          {'einverstanden.'}
        </CookieConsent>
      </ThemeProvider>
    </>
  );
};
export default Layout;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};
